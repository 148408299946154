import React, { useEffect, useState, useRef} from "react";
import { ButtonBase } from '@mui/material'
import GoogleIcon from '../styles/svg-icons/GoogleIcon'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link, useNavigate, useLocation} from 'react-router-dom'
import { toast, Slide, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessToastIcon from "../styles/svg-icons/SuccessToastIcon";
import { motion } from "framer-motion";
import ShieldIcon from "../styles/svg-icons/ShieldIcon";
import AilaysaBlackIcon from "../styles/svg-icons/AilaysaBlackIcon";

const Login = (props) => {
    const { 
        switchView,
        setSwitchView,
        isAcctVerified,
        handleChangeSwitch,
        isSeperateAuth
    } = props

    const navigate = useNavigate()
    const location = useLocation()

    const [pwdVisibility, setPwdVisibility] = useState(false);

    const variants = {
        hidden: {
          opacity: 0,
          y: -10,
          transition: {
            ease: "easeInOut",
            duration: 0.4,
            y: {
                delay: 0.4,
                duration: 0.4
            },
            opacity: {
                delay: 0.4,
                duration: 0.4
            }
          },
          onanimationend: {
            display: "none"
          }
        },
        shown: {
          opacity: 1,
          y: 0,
          display: "flex",
          transition: {
            ease: "easeInOut",
            duration: 0.4,
            y: {
                delay: 0.4,
                duration: 0.4
            },
            opacity: {
                delay: 0.4,
                duration: 0.4
            }
          },
        }
    };

    return (
        <div className="signup-main-wrapper login">
            <div className="signup-inner-wrapper">
                <div className="ai-mob-logo" onClick={() => navigate("/")}>
                    <AilaysaBlackIcon />
                </div>
                <motion.div variants={variants} initial="hidden" animate={(isAcctVerified || location.state?.isPwdUpdated) ? "shown" : "hidden"} className="acc-verified-toast-wrapper">
                    {
                        location.state?.isPwdUpdated ?
                            <ShieldIcon />
                        : isAcctVerified &&
                            <SuccessToastIcon />
                    }
                    <span className="text">{isAcctVerified ? "Your account is verified successfully!" : location.state?.isPwdUpdated && "Password is updated successfully!"}</span>
                </motion.div>
                <h3 className="title">Log in</h3>
                <ButtonBase className="signup-google-btn">
                    <div className="signup-btn-text-wrapper">
                        <GoogleIcon style="google-icon" />
                        <span className="text">Log in with Google</span>
                    </div>
                </ButtonBase>
                <div className="or-wrapper"><span>OR</span></div>
                <div className="form-main-wrapper">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" name="email" className="form-text-box"/>
                    </div>
                    <div className="form-group pwd">
                        <div className="label-row">
                            <label htmlFor="password">Password</label>
                            <p className="forgot-pwd" onClick={() =>{isSeperateAuth ? navigate("/auth/forgot-password") : handleChangeSwitch("forgot-pwd")}}>Forgot your password?</p>
                        </div>
                        <input id="password" type={pwdVisibility ? "text" : "password"} name="password" className="form-text-box"/>
                        <span className="pwd-visibility" onClick={() => setPwdVisibility(!pwdVisibility)}>
                            {
                                pwdVisibility ?
                                <VisibilityOutlinedIcon className="visible-icon"/>
                                :
                                <VisibilityOffOutlinedIcon className="visible-icon"/>
                            }
                        </span>
                    </div>
                </div>
                <ButtonBase className="global-submit-btn">
                    Log in
                </ButtonBase>
                <div className="login-link-wrapper">
                    <p>Don’t have an account? <span onClick={() => {isSeperateAuth ? navigate("/auth/signup") : handleChangeSwitch("signup")}}>Sign up, Free!</span></p>
                </div>
            </div>
        </div>
    )
}

export default Login
import React from 'react'

const PaperRocketIcon = () => {
  return (
    <svg id="Group_1299" data-name="Group 1299" xmlns="http://www.w3.org/2000/svg" width="63.421" height="52.027" viewBox="0 0 63.421 52.027">
        <defs>
            <linearGradient id="linear-gradient" x1="0.268" y1="1.274" x2="0.552" y2="0.192" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0e6ee2"/>
            <stop offset="1" stop-color="#3a94ff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.203" y1="0.823" x2="0.694" y2="0.263" href="#linear-gradient"/>
        </defs>
        <g id="Group_289" data-name="Group 289" transform="translate(0 0)">
            <path id="Path_882" data-name="Path 882" d="M41.762,65.083l17.8,5.813,45.622-27.442Z" transform="translate(-41.762 -43.454)" fill="url(#linear-gradient)"/>
            <path id="Path_883" data-name="Path 883" d="M81.208,70.855l8.424,15.656,1.584-12.73L126.84,43.454Z" transform="translate(-63.419 -43.454)" fill="#1269d1"/>
            <path id="Path_884" data-name="Path 884" d="M103.295,73.8l15.926,20.1L138.99,43.454Z" transform="translate(-75.569 -43.454)" fill="url(#linear-gradient-2)"/>
            <path id="Path_885" data-name="Path 885" d="M101.391,110.693l-1.555,12.673,6.253-6.794Z" transform="translate(-73.652 -80.359)" fill="#105bb5"/>
        </g>
        <path id="Path_886" data-name="Path 886" d="M93.773,146.585a1.1,1.1,0,0,0-.563.654q-.373,1.654-.746,3.308a1.216,1.216,0,0,0,.064.861,1.127,1.127,0,0,0,1.514.5,1.1,1.1,0,0,0,.563-.654q.373-1.654.746-3.308a1.216,1.216,0,0,0-.064-.861,1.127,1.127,0,0,0-1.514-.5Z" transform="translate(-69.566 -99.999)" fill="#a5ceff"/>
        <path id="Path_887" data-name="Path 887" d="M78.761,136.26l-3.049,2.5a1.137,1.137,0,0,0-.074,1.594,1.154,1.154,0,0,0,1.594.074l3.049-2.5a1.136,1.136,0,0,0,.074-1.593A1.154,1.154,0,0,0,78.761,136.26Z" transform="translate(-60.197 -94.241)" fill="#a5ceff"/>
    </svg>

  )
}

export default PaperRocketIcon
module.exports = {
      API_URL: process.env.REACT_APP_API_URL,
      CDN_DOMAIN: process.env.REACT_APP_CDN,
      BASE_URL: process.env.REACT_APP_API_BASE_URL,
      SOLNIGAR_BASE_URL: process.env.REACT_APP_SOLNIGAR_BASE_URL,
      aiDomainConfigs: {
            AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
            BASE_DOMAIN: process.env.REACT_APP_BASE_DOMAIN,
            HOST_DOMAIN: process.env.REACT_APP_HOST_DOMAIN,
            CHAT_DOMAIN: process.env.REACT_APP_CHAT_DOMAIN,
            TRANSEDITOR_DOMAIN: process.env.REACT_APP_TRANSEDITOR_DOMAIN,
            MARKETPLACE_DOMAIN: process.env.REACT_APP_MARKETPLACE_DOMAIN,
            KNOWLEDGEBASE_DOMAIN: process.env.REACT_APP_KNOWLEDGEBASE_DOMAIN,
            COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
            USER_STATE: process.env.REACT_APP_USER_STATE_COOKIE_KEY_NAME,
            PLAN_STATE: process.env.REACT_APP_PLAN_STATE_COOKIE_KEY_NAME,
            CDN_DOMAIN: process.env.REACT_APP_CDN,
            DESIGNER_HOST_DOMAIN: process.env.REACT_APP_DESIGNER_HOST,
            HOST_URL: "/",
          },
};
  
import React, { useEffect, useState, useRef} from "react";
import { ButtonBase } from '@mui/material'
import GoogleIcon from '../../styles/svg-icons/GoogleIcon'
import { Link , useNavigate} from 'react-router-dom'
import AilaysaBlackIcon from "../../styles/svg-icons/AilaysaBlackIcon";

const SignupStepOne = (props) => {
    const { 
        switchView,
        setSwitchView,
        signUpSteps,
        setSignUpSteps,
        handleSignupStepChange,
        handleChangeSwitch,
        isSeperateAuth,
        setLoginModal
    } = props

    const navigate = useNavigate()
    
  return (
    <div className="signup-main-wrapper">
        <div className="signup-inner-wrapper">
            <div className="ai-mob-logo" onClick={() => navigate("/")}>
                <AilaysaBlackIcon />
            </div>
            <h3 className="title">Sign up</h3>
            <ButtonBase className="signup-google-btn">
                <div className="signup-btn-text-wrapper">
                    <GoogleIcon style="google-icon" />
                    <span className="text">Sign up with Google</span>
                </div>
            </ButtonBase>
            <div className="or-wrapper"><span>OR</span></div>
            <div className="form-main-wrapper">
                <div className="form-group">
                    <label>Email</label>
                    <input id="email" type="email" name="email" className="form-text-box"/>
                </div>
            </div>
            <div className="agree-wrapper">
                <p>By signing up, I agree to Ailaysa's <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link></p>
            </div>
            <ButtonBase className="global-submit-btn" onClick={() => handleSignupStepChange("step-2")}>
                Continue
            </ButtonBase>
            <div className="login-link-wrapper">
                <p>Already have an account? <span onClick={() =>{isSeperateAuth ? navigate("/auth/login")  : handleChangeSwitch("login")}}>Login</span></p>
            </div>
            <div className="marketplace-login-link-wrapper">
                <p>Are you a professional translator or agency offering services? Go to Ailaysa Marketplace <Link to="/auth/vendor-signup" onClick={() => setLoginModal(false)}>Sign up</Link></p>
            </div>
        </div>
    </div>
  )
}

export default SignupStepOne
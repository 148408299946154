import React from 'react'

const ForgotLockIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70.835" height="69.672" viewBox="0 0 70.835 69.672">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" y1="0.94" x2="0.5" y2="-1.941" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0078d4"/>
            <stop offset="1" stop-color="#00b1ff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.521" y1="1" x2="0.531" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0e6ee2"/>
            <stop offset="1" stop-color="#52a0fe"/>
            </linearGradient>
        </defs>
        <g id="forgot-pwd" transform="translate(-1326.463 -1247.963)">
            <g id="Group_1280" data-name="Group 1280" transform="translate(1343.033 1258.251)">
            <g id="Group_1279" data-name="Group 1279" transform="translate(6.177)">
                <path id="Path_2921" data-name="Path 2921" d="M1423.98,1297.87h-6.443v-8a9.194,9.194,0,1,0-18.387,0v8h-6.443v-8a15.636,15.636,0,1,1,31.273,0Z" transform="translate(-1392.709 -1274.234)" fill="#a5ceff"/>
            </g>
            <path id="Path_2922" data-name="Path 2922" d="M1389.49,1405.11h-.8a11.757,11.757,0,0,1-11.76-11.756v-.806a12.562,12.562,0,0,0,12.56,12.562Z" transform="translate(-1376.93 -1346.232)" fill="url(#linear-gradient)"/>
            <path id="Path_2923" data-name="Path 2923" d="M1420.74,1339.3v14.5a11.756,11.756,0,0,1-11.756,11.756h-19.492a12.562,12.562,0,0,1-12.563-12.564V1339.3a11.76,11.76,0,0,1,11.76-11.76h20.294a11.759,11.759,0,0,1,11.757,11.758Z" transform="translate(-1376.93 -1306.672)" stroke="rgba(0,0,0,0)" stroke-width="1" fill="url(#linear-gradient-2)"/>
            <path id="Path_2924" data-name="Path 2924" d="M1429.42,1360.13a6.254,6.254,0,1,0-8.444,5.858l-.2,4.38a.939.939,0,0,0,.9.981H1425a.94.94,0,0,0,.937-.942c0-.013,0-.027,0-.04l-.226-4.536a6.255,6.255,0,0,0,3.7-5.7Z" transform="translate(-1401.26 -1322.7)" fill="#fff"/>
            </g>
            <g id="Group_1283" data-name="Group 1283" transform="translate(1326.459 1260.852)">
            <g id="Group_1282" data-name="Group 1282">
                <g id="Group_1281" data-name="Group 1281">
                <path id="Path_2925" data-name="Path 2925" d="M1320.19,1297.41l-3.727-1.11.568-1.862,3.727,1.381-.111-4.241h1.888l-.123,4.3,3.666-1.356.568,1.875-3.79,1.122,2.444,3.345-1.53,1.16-2.3-3.554-2.221,3.468-1.543-1.123Z" transform="translate(-1316.459 -1291.581)" fill="#c9e1ff"/>
                </g>
            </g>
            </g>
            <g id="Group_1285" data-name="Group 1285" transform="translate(1386.844 1247.968)">
            <g id="Group_1284" data-name="Group 1284">
                <path id="Path_2926" data-name="Path 2926" d="M1503.79,1253.95l-3.827-1.14.583-1.911,3.826,1.418-.114-4.354h1.938l-.127,4.417,3.763-1.392.583,1.924-3.889,1.152,2.508,3.433-1.571,1.191-2.356-3.648-2.281,3.559-1.583-1.152Z" transform="translate(-1499.961 -1247.968)" fill="#c9e1ff"/>
            </g>
            </g>
        </g>
    </svg>
  )
}

export default ForgotLockIcon
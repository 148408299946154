import React from 'react'

const SuccessToastIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.006" height="25.006" viewBox="0 0 25.006 25.006">
        <defs>
            <linearGradient id="linear-gradient" x1="0.478" y1="1" x2="0.472" y2="0.018" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0e6ee2"/>
            <stop offset="1" stop-color="#3a94ff"/>
            </linearGradient>
        </defs>
        <g id="congrats-tick" transform="translate(-1690.962 -48.962)">
            <g id="Group_1278" data-name="Group 1278" transform="translate(1691.462 49.462)">
            <circle id="Ellipse_274" data-name="Ellipse 274" cx="12.003" cy="12.003" r="12.003" transform="translate(0 0)" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0.2" fill="url(#linear-gradient)"/>
            <circle id="Ellipse_275" data-name="Ellipse 275" cx="9.315" cy="9.315" r="9.315" transform="translate(2.688 2.688)" stroke="rgba(0,0,0,0)" stroke-width="1" fill="url(#linear-gradient)"/>
            <path id="Path_2920" data-name="Path 2920" d="M105.985,88.186a1.269,1.269,0,0,0-1.773-.074q-1.408,1.579-2.887,3.094-.729.746-1.475,1.475-.341.334-.686.663l-.375-.488L97.745,91.5a1.258,1.258,0,0,0-1.773-.074,1.288,1.288,0,0,0-.074,1.773l1.044,1.36c.321.419.621.868.969,1.263a1.482,1.482,0,0,0,1.388.521,1.925,1.925,0,0,0,.959-.572q.586-.551,1.162-1.113,2.325-2.267,4.489-4.694a1.284,1.284,0,0,0,.075-1.774Z" transform="translate(-88.952 -79.783)" fill="#fff"/>
            </g>
        </g>
    </svg>

  )
}

export default SuccessToastIcon
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Config from "../../Config/Config";
import ButtonBase from '@mui/material/ButtonBase';
import Cookies from "js-cookie";
import CalendarIcon from "../../styles/svg-icons/CalendarIcon";
import LocationIcon from "../../styles/svg-icons/locationIcon";
// import FrankfurtModalImg from "../../styles/image/frankfurtmodal-img.svg"
import FrankfurtModalImg from "../../styles/image/frankfurtmodal-image.jpg"


const FrankFurterOnloadModal = (props) => {
    let {handleAwardCloseModal} = props;

    const history = useNavigate();
  
    const handleCampaignRedirect = () => {
      history("/startuptn");
    }
  
    return (
        <section className="credits-modal-wrapper frankfurter-wrap">
            {/* <img src={FrankfurtModalImg} alt="frankfurtImg"/> */}
            <div className="img-with-content">
                <div className="left-container">
                    <img 
                        src={window.location.origin + "/assets/images/landing-page/frankfurter_img.png"} 
                        alt="frankfurt"
                        
                    />
                </div>
                <div className="right-container">
                    <img 
                        src={window.location.origin + "/assets/images/landing-page/Frankfurter_Buchmesse.png"} 
                        alt="frankfurter_buchemess"
                        className="frankfurt-logo"
                    />
                    <div className="credits-modal-row header">
                        <h1>We are at <br/> <span className="highlight"> Frankfurt Book Fair</span> 2024.</h1>
                        <h6>Explore Adaptive, Assistive and Agentic AI in translations and publishing workflows.</h6>
                    </div>
                    <div>
                        <p className="credits-modal-description">Meet us at</p>
                        <div className="hall-info-wrapper">
                            Hall 4.0 A69
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FrankFurterOnloadModal;

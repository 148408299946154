import React, { useEffect, useState, useRef} from "react";
import SignupStepOne from "./SignupStepsComponents/SignupStepOne";
import SignupStepTwo from "./SignupStepsComponents/SignupStepTwo";
import LoginVerifyMailSentConfirmation from "./SignupStepsComponents/LoginVerifyMailSentConfirmation";

const Signup = (props) => {
    const { 
        switchView,
        setSwitchView,
        handleChangeSwitch,
        isSeperateAuth,
        setLoginModal
    } = props

    const [signUpSteps, setSignUpSteps] = useState("step-1")

    const handleSignupStepChange = (switchTxt) => {
        setSignUpSteps(switchTxt)
    }

  return (
    <>
        {
            signUpSteps === "step-1" ?
            <SignupStepOne signUpSteps={signUpSteps} setSignUpSteps={setSignUpSteps} switchView={switchView} setSwitchView={setSwitchView} handleSignupStepChange={handleSignupStepChange} handleChangeSwitch={handleChangeSwitch} isSeperateAuth={isSeperateAuth} setLoginModal={setLoginModal}/>
            : signUpSteps === "step-2" ?
            <SignupStepTwo signUpSteps={signUpSteps} setSignUpSteps={setSignUpSteps} switchView={switchView} setSwitchView={setSwitchView} handleSignupStepChange={handleSignupStepChange} handleChangeSwitch={handleChangeSwitch}/>
            : signUpSteps === "step-3" &&
            <LoginVerifyMailSentConfirmation switchView={switchView} setSwitchView={setSwitchView} handleChangeSwitch={handleChangeSwitch} isSeperateAuth={isSeperateAuth}/>
        }
    </>
  )
}

export default Signup
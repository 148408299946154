import React from 'react'
import { ButtonBase } from '@mui/material'
import PaperRocketIcon from '../../styles/svg-icons/PaperRocketIcon'
import { Link , useNavigate, useLocation} from 'react-router-dom'
import AilaysaBlackIcon from '../../styles/svg-icons/AilaysaBlackIcon'

const LoginVerifyMailSentConfirmation = (props) => {
    const { 
        switchView,
        setSwitchView,
        handleChangeSwitch,
        isSeperateAuth
    } = props

    const navigate = useNavigate()
    const location = useLocation()


    return (
        <div className="signup-main-wrapper reset-pwd-mail-confirm">
            <div className={"signup-inner-wrapper " + (location.state?.isVendorSignup ? "vendor-signup" : "")}>
                <div className="ai-mob-logo" onClick={() => navigate("/")}>
                    <AilaysaBlackIcon />
                </div>
                <h3 className="title">Verify your email to proceed</h3>
                <div className="confirm-icon-wrapper">
                    <PaperRocketIcon />
                </div>
                <div className="mail-sent-info">
                    <p className="note">A verification link has been sent to</p>
                    <p className="email">Sathyaprakash@gmail.com</p>
                </div>
                {
                    isSeperateAuth ?
                    <ButtonBase component={Link} className="global-submit-btn" to="/auth/login">
                        Resend Verification Email
                    </ButtonBase>
                    :
                    <ButtonBase className="global-submit-btn" onClick={() => handleChangeSwitch("login")}>
                        Resend Verification Email
                    </ButtonBase>
                }
            </div>
        </div>
    )
}

export default LoginVerifyMailSentConfirmation
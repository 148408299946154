import React, { useEffect, useState, useRef} from "react";
import { ButtonBase } from '@mui/material'
import GoogleIcon from '../../styles/svg-icons/GoogleIcon'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link, useNavigate } from 'react-router-dom'
import AilaysaBlackIcon from "../../styles/svg-icons/AilaysaBlackIcon";

const SignupStepTwo = (props) => {
    const { 
        signUpSteps,
        setSignUpSteps,
        switchView,
        setSwitchView,
        handleChangeSwitch,
        handleSignupStepChange
    } = props

    const navigate = useNavigate()
    const [pwdVisibility, setPwdVisibility] = useState(false);

  return (
    <div className="signup-main-wrapper signup-step-2">
        <div className="signup-inner-wrapper">
            <div className="ai-mob-logo" onClick={() => navigate("/")}>
                <AilaysaBlackIcon />
            </div>
            <div className="header-row">
                <span className="back-btn-wrap" onClick={() => handleSignupStepChange("step-1")}><ArrowBackIosNewIcon className="arrow-back"/></span>
                <h3 className="title">Continue with email</h3>
            </div>
            
            <div className="form-main-wrapper">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" name="name" className="form-text-box"/>
                </div>
                <div className="form-group pwd">
                    <label htmlFor="password">Password</label>
                    <input id="password" type={pwdVisibility ? "text" : "password"} name="password" className="form-text-box"/>
                    <span className="pwd-visibility" onClick={() => setPwdVisibility(!pwdVisibility)}>
                        {
                            pwdVisibility ?
                            <VisibilityOutlinedIcon className="visible-icon"/>
                            :
                            <VisibilityOffOutlinedIcon className="visible-icon"/>
                        }
                    </span>
                </div>
            </div>
            <ButtonBase className="global-submit-btn" onClick={() => handleSignupStepChange("step-3")}>
                Create my account
            </ButtonBase>
        </div>
    </div>
  )
}

export default SignupStepTwo
import React from 'react'

const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48.91" height="51.682" viewBox="0 0 48.91 51.682">
        <defs>
            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0e6ee2"/>
            <stop offset="1" stop-color="#4b9dff"/>
            </linearGradient>
        </defs>
        <g id="mail-auth" transform="translate(-1455.188 -1482.62)">
            <rect id="Rectangle_1833" data-name="Rectangle 1833" width="48.838" height="33.745" transform="translate(1455.188 1500.556)" fill="url(#linear-gradient)"/>
            <path id="Path_2927" data-name="Path 2927" d="M1455.39,1530.39l24.268,19.788,24.422-19.788" transform="translate(-0.125 -29.833)" fill="#fff"/>
            <path id="Path_2928" data-name="Path 2928" d="M1455.19,1587.733l24.345-19.557,24.5,19.557Z" transform="translate(0 -53.431)" fill="#0e6ee2"/>
            <path id="Path_2929" data-name="Path 2929" d="M1455.488,1500.464l25.106-17.834a.049.049,0,0,1,.059,0l23.6,17.835a.053.053,0,0,1,.017.056.05.05,0,0,1-.046.034h-48.709a.051.051,0,0,1-.029-.01.055.055,0,0,1-.018-.025.054.054,0,0,1,0-.032.05.05,0,0,1,.018-.025Z" transform="translate(-0.177)" fill="#2582f2"/>
        </g>
    </svg>
  )
}

export default MailIcon
import React from 'react'

const GoogleIcon = (props) => {
  const { style } = props
  return (
    <svg className={style} xmlns="http://www.w3.org/2000/svg" width="19.572" height="19.997" viewBox="0 0 19.572 19.997">
        <g id="Google__G__Logo" transform="translate(26.754 -39.239)">
            <path id="Path_2842" data-name="Path 2842" d="M-5.181,51.13a11.584,11.584,0,0,0-.158-1.891h-9.415V53h5.391a4.642,4.642,0,0,1-2,2.983v2.5h3.216A9.776,9.776,0,0,0-5.181,51.13Z" transform="translate(-2.002 -1.668)" fill="#4285f4"/>
            <path id="Path_2843" data-name="Path 2843" d="M-16.54,61.619a9.524,9.524,0,0,0,6.607-2.425l-3.216-2.5a6.032,6.032,0,0,1-3.391.967,5.971,5.971,0,0,1-5.607-4.133h-3.316V56.1A9.983,9.983,0,0,0-16.54,61.619Z" transform="translate(-0.215 -2.384)" fill="#34a853"/>
            <path id="Path_2844" data-name="Path 2844" d="M-22.363,52.25a5.8,5.8,0,0,1-.317-1.908,6.028,6.028,0,0,1,.317-1.908V45.859h-3.316a9.882,9.882,0,0,0-1.075,4.483,9.882,9.882,0,0,0,1.075,4.483Z" transform="translate(0 -1.104)" fill="#fbbc05"/>
            <path id="Path_2845" data-name="Path 2845" d="M-16.54,43.2a5.425,5.425,0,0,1,3.833,1.5l2.85-2.85a9.559,9.559,0,0,0-6.682-2.608,9.983,9.983,0,0,0-8.924,5.516l3.316,2.575A5.971,5.971,0,0,1-16.54,43.2Z" transform="translate(-0.215)" fill="#ea4335"/>
        </g>
    </svg>
  )
}

export default GoogleIcon
import React from "react";
import Config from "../../Config/Config";



const PageOnload = () => {
  return (
    <section>
        <div className="award-image-wrapper">
            <img src={Config.aiDomainConfigs.CDN_DOMAIN + "/assets/images/citlob-award-img.jpg"}/>
        </div>
    </section>
  );
};

export default PageOnload;

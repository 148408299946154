import React from "react";

function ShieldIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.108"
      height="25"
      viewBox="0 0 20.108 25"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.472"
          y1="0.982"
          y2="0.018"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0f70e4"></stop>
          <stop offset="1" stopColor="#3a94ff"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 1298" transform="translate(1.908 -1.969)">
        <g data-name="Group 312" transform="translate(-1.908 1.969)">
          <path
            fill="#a5ceff"
            d="M-89.33-19a17.256 17.256 0 01-10.06-15.612v-5.744A6.3 6.3 0 00-94.887-44h11.1a6.3 6.3 0 004.505 3.642v5.746A17.265 17.265 0 01-89.33-19z"
            data-name="Path 916"
            transform="translate(99.39 44)"
          ></path>
          <path
            fill="url(#linear-gradient)"
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
            d="M-87.708-19.394h0A14.224 14.224 0 01-96-32.262V-37a5.193 5.193 0 003.712-3h9.149a5.193 5.193 0 003.714 3v4.736a14.231 14.231 0 01-8.283 12.87z"
            data-name="Path 917"
            transform="translate(97.767 42.085)"
          ></path>
          <g
            fill="#fff"
            data-name="Group 311"
            transform="translate(5.377 7.515)"
          >
            <path
              d="M1 0h4.327v1.414a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1z"
              data-name="Rectangle 744"
              transform="rotate(45 -2.163 3.31)"
            ></path>
            <path
              d="M1 0h6.861a1 1 0 011 1v1.413H1a1 1 0 01-1-1V1a1 1 0 011-1z"
              data-name="Rectangle 745"
              transform="rotate(135 4.663 2.93)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ShieldIcon;
import { createSlice } from "@reduxjs/toolkit";

export const showModalSlice = createSlice({
  name: "showModal",
  initialState: {
    modal: {
      visible: false,
    },
    pending: null,
    error: false,
  },
  reducers: {
    showModalStart: (state) => {
      state.pending = true;
    },
    showModalSuccess: (state, action) => {
      state.pending = false;
      state.modal = action.payload;
    },
    showModalError: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { showModalStart, showModalSuccess, showModalError } =
  showModalSlice.actions;
export default showModalSlice.reducer;

import React from "react";

function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.129"
      height="22.327"
      viewBox="0 0 18.129 22.327"
    >
      <path
        fill="#0673f7"
        d="M144.058-894.9a1.982 1.982 0 001.449-.584 1.949 1.949 0 00.59-1.443 1.972 1.972 0 00-.589-1.449 1.976 1.976 0 00-1.456-.59 1.938 1.938 0 00-1.444.589 2 2 0 00-.577 1.456 1.947 1.947 0 00.584 1.444 1.972 1.972 0 001.443.577zm-.007 7.976a32.449 32.449 0 004.972-5.352 7.976 7.976 0 001.614-4.513 6.5 6.5 0 00-1.906-4.834 6.4 6.4 0 00-4.676-1.882 6.411 6.411 0 00-4.679 1.882 6.494 6.494 0 00-1.91 4.834 7.8 7.8 0 001.653 4.5 37.072 37.072 0 004.933 5.363zm0 3.253a39.609 39.609 0 01-6.8-6.913 10.612 10.612 0 01-2.256-6.2 8.661 8.661 0 012.73-6.7 9.058 9.058 0 016.327-2.516 9.11 9.11 0 016.329 2.509 8.632 8.632 0 012.748 6.7 10.574 10.574 0 01-2.269 6.2 40.071 40.071 0 01-6.808 6.918zm.001-13.251z"
        transform="translate(-135 906)"
      ></path>
    </svg>
  );
}

export default LocationIcon;
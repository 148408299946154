import React, { useEffect, useState, useRef, useCallback, Suspense, lazy } from "react";
import { useLocation, useNavigate, NavigationType, useNavigationType } from "react-router-dom";
// import { AnimatePresence } from "framer-motion";
// import AnimationLoader from "../src/loader/animation-loader";
import { checkSubscription, getProfileImages, getTokensFromCookies, getUserInfo, hasAccess, protect } from "./Config/ApiHelper";
import Container from '@mui/material/Container';
// import { BeatLoader, ClimbingBoxLoader, HashLoader, RotateLoader, ScaleLoader } from "react-spinners";
import { setUserCurrentSubscriptionPlanConfigs } from "./Config/PlanConfig";
import Cookies from "js-cookie";
import { ButtonBase } from '@mui/material';
import { BroadcastChannel } from "broadcast-channel";
import Config, { aiDomainConfigs } from "./Config/Config";
// import AOS from "aos";
// import "aos/dist/aos.css";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import PageOnload from "./Layouts/modals/PageOnloadModal";
// import LoadConfetti from "./animation/LoadConfetti";
// import CreditsOnload from "./Layouts/modals/CreditsOnloadModal";
import packageJson from '../package.json';
import CookieIcon from './styles/image/new-cookie-icon.svg'
import ImageWrapper from './styles/image/image-wrap.png'
import ScrollToTop from './ScrollUp'
import { CacheBuster } from './CacheBuster'
import Signup from "./authentication/Signup";
import CloseIcon from '@mui/icons-material/Close';
import Login from "./authentication/Login";
import ForgotPassword from "./authentication/ForgotPassword";
import HeaderTags from "./Layouts/HeadTags";
import { HelmetProvider } from 'react-helmet-async';
import StartupTnModal from "./Layouts/modals/StartUpTnModal";
import FrankFurterOnloadModal from "./Layouts/modals/FrankFurterOnloadModal";
import { KLBFModal } from "./Pages/book-fair-landing-pages/KLBFModal";

// import useScrollRestoration from "./Layouts/ScrollRestoration";

const AllRoutes = lazy(() => import('./AllRoutes'))
const CommonLoader = lazy(() => import('./Layouts/animations/CommonLoader'))



export const UserContext = React.createContext(null);

const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
};

function App(props) {
    // useScrollRestoration()
    const API_URL = Config.BASE_URL;
    const ACCESS_TOKEN_EXPIRATION_TIME = 800500;
    const permission_tokens = getTokensFromCookies();

    const history = useNavigate();
    // const { pathname } = useLocation();
    const location = useLocation();
    const channel = React.useMemo(() => new BroadcastChannel("logout"), []);
    let admin_email = "admin@ailaysa.com";

    let accessToken = permission_tokens?.token;
    let refreshToken = permission_tokens?.refresh_token;

    const isPop = useBackButton();
    // console.log({ isPop });

    const scrollToTop = () => window.scrollTo(0, 0);

    const [userInfo, setUserInfo] = useState([]);
    const [profileImages, setProfileImages] = useState([]);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isErrored, setErrored] = useState(false);
    const [logout, setLogout] = useState(false);
    const [metaPathname, setMetaPathname] = useState(null);
    const [isAwardRecognitionModalShow, setIsAwardRecognitionModalShow] = useState(true);
    const [AwardRecognitionImageSeen, setAwardRecognitionImageSeen] = useState(true);
    const [confettiShow, setConfettiShow] = useState(false);
    const [switchView, setSwitchView] = useState("signup");
    const [NavBgColor, setNavBgColor] = useState(null)
    const [currentLanguage, setCurrentLanguage] = useState("en")


    // useEffect(() => {
    //     console.log(isAwardRecognitionModalShow)
    // }, [isAwardRecognitionModalShow])

    useEffect(() => {
        if (location.pathname !== location.pathname.toLowerCase()) {
            return history(location.pathname.toLowerCase());
        }
    }, [location.pathname])
    

    useEffect(() => {
        scrollToTop();
    }, [location, isPop]);

    useEffect(() => {
        window.addEventListener("beforeunload", scrollToTop);
        return () => {
            window.removeEventListener("beforeunload", scrollToTop);
        };
    }, []);


    useEffect(() => {
        if (currentLanguage !== "") {
            Cookies.set("CurrenLanguage", currentLanguage, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
        }
        document.body.classList = currentLanguage || "en";
    }, [currentLanguage])

    const awardModalClickOutside = useRef();

    let error_statement = "error_500";
    const [isCookieAccepted, setIsCookieAccepted] = useState(true);


    useEffect(() => {
        const position = window.pageYOffset;
        if (position === 0) {
            if (document.body.classList.contains('theme-white')) {
                document.body.classList?.add("theme-black");
                document.body.classList?.remove("theme-white");
                setNavBgColor(true)
            }
            // else{
            //     document.body.classList?.add("theme-white");
            //     document.body.classList?.remove("theme-black");
            //     setNavBgColor(false)
            // }
        }
    }, [window.pageYOffset, window.location.pathname])


    useEffect(() => {
        // Change the state after the initial load
        setNavBgColor(true);
    }, []);

    useEffect(() => {
        if (NavBgColor) {
            document.body.classList?.add("theme-black");
            document.body.classList?.remove("theme-white");
        } else {
            document.body.classList?.add("theme-white");
            document.body.classList?.remove("theme-black");
        }
    }, [NavBgColor])

    const handleAwardCloseModal = () => {
        Cookies.set("frankfurt-book-fair", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
        setAwardRecognitionImageSeen(true)
        setIsAwardRecognitionModalShow(true)
    }

    const handleAwardModalCloseEsc = () => {
        Cookies.set("frankfurt-book-fair", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
        setAwardRecognitionImageSeen(true)
        setIsAwardRecognitionModalShow(true)
    }

    //close modal on ecs key press
    const keyPress = useCallback(
        (e) => {
            if (e.which === 27) {
                Cookies.set("frankfurt-book-fair", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
                setAwardRecognitionImageSeen(true)
                setIsAwardRecognitionModalShow(true)
            }
        },
        [setIsAwardRecognitionModalShow, isAwardRecognitionModalShow]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);
        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);


    /* Check for clicing outside of the dropdown */
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (awardModalClickOutside.current && !awardModalClickOutside.current.contains(e.target)) {
                Cookies.set("frankfurt-book-fair", true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 * 5 });
                setAwardRecognitionImageSeen(true)
                setIsAwardRecognitionModalShow(true)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    // useEffect(() => {
    //     AOS.init({
    //         once: true
    //     })
    // }, []);


    useEffect(() => {
        if (typeof Cookies.get("frankfurt-book-fair") == "undefined") {
            setAwardRecognitionImageSeen(false)
        }
    }, []);

    

    useEffect(() => {
        if (
            window.location.pathname === "/20000freewords" ||
            window.location.pathname.includes("signup") ||
            window.location.pathname.includes("login") ||
            window.location.pathname.includes("forgot-password") ||
            window.location.pathname.includes("reset-password") ||
            window.location.pathname.includes("verify-email") ||
            window.location.pathname.includes("vendor-verify-email") ||
            window.location.pathname.includes("token-expired") ||
            window.location.pathname.includes("vendor-signup"))
        // ||
        // window.location.pathname === "/world-book-day-offer-tamil")
        {
            history("/")
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (!isAuthenticated && (window.location.pathname === "/marketplace")){
            window.location.href = Config.aiDomainConfigs.AUTH_DOMAIN + "/sp-signup"
        }else if (isAuthenticated && (window.location.pathname === "/marketplace")){
            window.location.href = Config.aiDomainConfigs.MARKETPLACE_DOMAIN
        }
    }, [isAuthenticated, window.location.pathname])

    useEffect(() => {
        if (window.location.pathname === "/world-book-day-offer-tamil") {
            document.body.classList?.add("__tam__");
        } else {
            document.body.classList?.remove("__tam__");
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (window.location.pathname === "/about") {
            setMetaPathname("about")
        } else if (window.location.pathname === "/support") {
            setMetaPathname("support")
        }
        // else if(window.location.pathname === "/become-a-vendor") {
        //     setMetaPathname("become-a-vendor")
        // }
        else if (window.location.pathname === "/career") {
            setMetaPathname("career")
        } else if (window.location.pathname === "/contact") {
            setMetaPathname("contact")
        } else if (window.location.pathname === "/pricing") {
            setMetaPathname("pricing")
        } else if (window.location.pathname === "/ailaysa-transeditor") {
            setMetaPathname("ailaysa-transeditor")
        } else if (window.location.pathname === "/ailaysa-projects") {
            setMetaPathname("ailaysa-projects")
        } else if (window.location.pathname === "/ailaysa-writer") {
            setMetaPathname("ailaysa-writer")
        } else if (window.location.pathname === "/ailaysa-speech") {
            setMetaPathname("ailaysa-speech")
        } else if (window.location.pathname === "/ailaysa-marketplace") {
            setMetaPathname("ailaysa-marketplace")
        } else if (window.location.pathname === "/privacy-policy") {
            setMetaPathname("privacy-policy")
        } else if (window.location.pathname === "/terms-of-service") {
            setMetaPathname("terms-of-service")
        } else if (window.location.pathname === "/world-book-day-offer") {
            setMetaPathname("world-book-day-offer")
        } else if (window.location.pathname === "/bibf-offer") {
            setMetaPathname("bibf-offer")
        } else if (window.location.pathname === "/newdelhi2024") {
            setMetaPathname("newdelhi2024")
        } else if (window.location.pathname === "/kanitamil2024") {
            setMetaPathname("kanitamil2024")
        } else {
            setMetaPathname("")
        }
    }, [window.location.pathname])


    // useEffect(() => {
    //     if (!isAuthenticated 
    //             // && window.location.pathname == "/" || window.location.pathname.includes("/pricing")
    //         ) {
    //         setAwardRecognitionImageSeen(false)
    //     }
    // }, [window.location.pathname]);

    useEffect(() => {
        if (!isAuthenticated && typeof Cookies.get(aiDomainConfigs.PLAN_STATE) !== "undefined") {
            Cookies.remove(aiDomainConfigs.PLAN_STATE, {
                domain: aiDomainConfigs.COOKIE_DOMAIN,
            });
        }
    }, [isAuthenticated])


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (!AwardRecognitionImageSeen  && !isAuthenticated && (!window.location.pathname.includes("/startuptn"))) {
    //             setIsAwardRecognitionModalShow(false)
    //         }
    //     }, 2100);

    //     return () => clearInterval(interval);
    // }, [AwardRecognitionImageSeen, window.location.pathname]);

    useEffect(() => {
        channel.onmessage = (message) => setLogout(message?.logout);
        if (typeof Cookies.get("cookieAccepted") == "undefined") setIsCookieAccepted(false);
    }, []);


    // const modaloption = {
    //     closeMaskOnClick: false,
    //     onClose: handleAwardCloseModal,
    // };

    useEffect(() => {
        if (logout) {
            Cookies.set("global_logout", true, {
                domain: aiDomainConfigs.COOKIE_DOMAIN,
            });
            window.location.href = aiDomainConfigs.BASE_DOMAIN;
        }
    }, [logout]);

    const acceptCookie = () => {
        Cookies.set("cookieAccepted", true, {
            domain: aiDomainConfigs.COOKIE_DOMAIN,
            expires: 30, // no. of days to expire 
        });
        setIsCookieAccepted(true);
    };

    useEffect(() => {
        protect(accessToken, refreshToken);
        setInterval(() => {
            protect(accessToken, refreshToken);
        }, ACCESS_TOKEN_EXPIRATION_TIME);
    }, [accessToken]);

    useEffect(() => {
        function getSomething() {
            return hasAccess(accessToken, refreshToken)
                .then((res) => (res !== undefined ? setAccessables() : setRedirection()))
                .catch((e) => console.log(e));
        }
        getSomething()
    }, []);

    useEffect(() => {
        async function getSomething() {
            if (isAuthenticated) {
                try {
                    let check_subscription = await checkSubscription(accessToken);
                    setUserCurrentSubscriptionPlanConfigs(check_subscription);
                    // console.log(check_subscription)
                } catch (error) {
                    console.log(error);
                }
            }
        }
        getSomething()
    }, [localStorage, isAuthenticated]);

    useEffect(() => {
        // document.addEventListener("visibilitychange", checkIfUserAuthenticationCookieAvailable);

        // return () => document.removeEventListener("visibilitychange", checkIfUserAuthenticationCookieAvailable)
    }, [])
    

    const checkIfUserAuthenticationCookieAvailable = (e) => {
        if (document.visibilityState === "visible") {
            // console.log("Document is active");
            let userCacheData = JSON.parse(
                typeof Cookies.get(aiDomainConfigs.USER_STATE) != "undefined" ? Cookies.get(aiDomainConfigs.USER_STATE) : null
            );
            if(!userCacheData?.token){
                if(!Cookies.get('auth-refresh')){
                    window.location.reload()
                    Cookies.set('auth-refresh', true, { domain: aiDomainConfigs.COOKIE_DOMAIN })
                }
            }else if(userCacheData?.token){
                if(Cookies.get('auth-refresh')) Cookies.remove('auth-refresh');
            }
        } else {
            // console.log("Document is inactive");
        }
    } 
    
    const setAccessables = () => {
        setAuthenticated(true);
        getUserInfo(accessToken)
            .then((res) => {
                res?.pk && setUserInfo(res)
                //  : setErrored(true);
            })
            .catch((err) => {
                window.location.href = aiDomainConfigs.AUTH_DOMAIN;
                // setErrored(true);
            });
        getProfileImages(accessToken)
            .then((res) => setProfileImages(res))
            .catch((e) => console.log(e));
    };

    const setRedirection = () => {
        setAuthenticated(false);
        // window.location.href = aiDomainConfigs.AUTH_DOMAIN;
        // history.push("/login");
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setConfettiShow(true)
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setConfettiShow(true)
        }, 1500);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const loadData = () => {
            new Promise((r) => setTimeout(r, 1000));
            setLoading((loading) => !loading);
        };
        return loadData();
    }, []);


    const handleChangeSwitch = (switchTxt) => {
        setSwitchView(switchTxt)
    }

    return (
        <React.Fragment>
            <HeaderTags
                path={metaPathname}
            />
            <CacheBuster
                currentVersion={packageJson?.version}
                isEnabled={true}
            />
            <Suspense fallback={<CommonLoader />}>
                <ScrollToTop />
                {/* {isErrored === false ? ( */}
                <UserContext.Provider
                    value={{
                        userInfo,
                        profileImages,
                        accessToken,
                        refreshToken,
                        permission_tokens,
                    }}
                >
                    <AllRoutes NavBgColor={NavBgColor} loginModal={loginModal} setLoginModal={setLoginModal} setNavBgColor={setNavBgColor} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </UserContext.Provider>
                {/* // ) : (
                    //     <div>
                    //         <Container className="p-4">
                    //             <div className="d-flex">
                    //                 <h2 style={{ fontSize: 230 }}>500</h2>
                    //                 <div className="mt-3">
                    //                     <p className="mt-5 ml-3" style={{ fontSize: 30 }}>
                    //                         {error_statement}
                    //                     </p>
                    //                     <>
                    //                         <p className="pl-3">error_500_serious_statement_1</p>
                    //                         <p className="pl-3">error_500_serious_statement_2", { admin_email }</p>
                    //                     </>
                    //                 </div>
                    //             </div>
                    //             <p className="pl-3">error_500_serious_statement_3</p>
                    //         </Container>
                    //     </div>
                    // )} */}
                {(!isCookieAccepted
                    // && location.pathname !== '/20000freewords'
                    && location.pathname !== '/world-book-day-offer'
                    && location.pathname !== '/world-book-day-offer-tamil'
                ) && (
                        <div className="ai-cookie-policy-container active-anim">
                            <div className="container ai-cookie-policy-section">
                                <div className="ai-cookie-policy-cont">
                                    <div className="mob-ai-cookie-policy-cont">
                                        <img src={CookieIcon} alt="cookie-icon" />
                                        <div className="mob-content-align">
                                            <p>By clicking “Accept”, you agree Ailaysa can store cookies on your device and disclose information in accordance with our Cookie Policy.</p>
                                        </div>
                                    </div>
                                    <ButtonBase onClick={() => acceptCookie()}>
                                        <div className="ai-cookie-button">
                                            Accept
                                        </div>
                                    </ButtonBase>
                                </div>
                            </div>
                        </div>
                    )}
                <Rodal visible={loginModal} showCloseButton={false} onClose={() => { }} className="login-modal-wrapper">
                    <div className="login-modal-close" onClick={() => { setLoginModal(false); handleChangeSwitch("signup") }}>
                        <CloseIcon className="close" />
                    </div>
                    <div className="login-modal-inner-wrapper">
                        <div className="login-modal-image-section">
                            <div className="login-modal-col">
                                <h1 className="title">Ailaysa's <br />New <span>Multiligual</span> Avatar!</h1>
                                <div className="image-wrapper">
                                    <img src={ImageWrapper} alt="image-wraper" />
                                </div>
                            </div>
                        </div>
                        <div className="login-modal-work-section">
                            {
                                switchView === "signup" ?
                                    <Signup switchView={switchView} setSwitchView={setSwitchView} handleChangeSwitch={handleChangeSwitch} setLoginModal={setLoginModal} />
                                    : switchView === "login" ?
                                        <Login switchView={switchView} setSwitchView={setSwitchView} handleChangeSwitch={handleChangeSwitch} />
                                        : switchView === "forgot-pwd" ?
                                            <ForgotPassword switchView={switchView} setSwitchView={setSwitchView} handleChangeSwitch={handleChangeSwitch} />
                                            :
                                            ""
                            }

                        </div>
                    </div>
                </Rodal>
            </Suspense>
                {
                    (!isAwardRecognitionModalShow 
                    && !isAuthenticated && (!window.location.pathname.includes("/event-landing"))
                    ) &&
                    <div className="award-recognition-modal credist-modal-wrapper">
                        <div className="award-recognition-modal-bg credist-modal"></div>
                        <div className="award-recognition-modal-wrapper" ref={awardModalClickOutside}>
                            <div className="award-recognition-modal-inner-wrapper">
                                <div className="ai-modal-header">
                                    <span className="modal-close-btn" onClick={handleAwardCloseModal}>
                                        <CloseIcon className="close-icon"/>
                                    </span>
                                </div>
                                <FrankFurterOnloadModal handleAwardCloseModal={handleAwardCloseModal} />
                                {/* <KLBFModal handleAwardCloseModal={handleAwardCloseModal} /> */}
                            </div>  
                        </div>
                    </div>
                }
        </React.Fragment>
    );
}

export default App;

import React, {Component} from 'react'
import {Helmet} from "react-helmet-async"
import Config from '../Config/Config';


function HeaderTags(props) {

    const {
        // homeSeoData,
        // aboutSeoData,
        // beSeoData,
        // careerSeoData,
        // contactSeoData,
        // pricingSeoData,
        // writerAppsSeoData,
        // projectsAppsSeoData,
        // transeditorAppsSeoData,
        // speechAppsSeoData,
        // marketAppsSeoData,
        // supportSeoData,
        path
      } = props   



    switch (path){
        case 'support':{
            return(
                <Helmet>
                    <title>Support</title>
                    <meta data-react-helmet="true" name="title" content="Support" />
                    <meta data-react-helmet="true" name="description" content="Get support for your AI language tasks with Ailaysa, including tech support and custom training." />
                    <meta data-react-helmet="true" name="keywords" content="Support, Help, Plans, tech support, custom training" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/support" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Support" />
                    <meta data-react-helmet="true" property="og:description" content="Get support for your AI language tasks with Ailaysa, including tech support and custom training." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/support" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Support"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Get support for your AI language tasks with Ailaysa, including tech support and custom training." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'ailaysa-marketplace':{
            return(
                <Helmet>
                    <title>Ailaysa Marketplace</title>
                    <meta data-react-helmet="true" name="title" content="Ailaysa Marketplace" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa Marketplace is the trusted destination to hire freelance translators, editors and agencies. Supporting over 100 languages." />
                    <meta data-react-helmet="true" name="keywords" content="Freelancer Marketplace. Vendor marketplace, freelance translators, freelance editors, language services company, translation agencies, LSPs" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/ailaysa-marketplace" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Ailaysa Marketplace" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa Marketplace is the trusted destination to hire freelance translators, editors and agencies. Supporting over 100 languages." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/ailaysa-marketplace" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Ailaysa Marketplace"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa Marketplace is the trusted destination to hire freelance translators, editors and agencies. Supporting over 100 languages." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'ailaysa-speech':{
            return(
                <Helmet>
                    <title>Ailaysa Speech</title>
                    <meta data-react-helmet="true" name="title" content="Ailaysa Speech" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa's AI Speech can assist you in transforming transcribe (audio to text) or speech generation (text to audio). it enables speech to speech translations." />
                    <meta data-react-helmet="true" name="keywords" content="transcription, AI transcription, text to speech, speech to text, AI voices, AI voice generation" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/ailaysa-speech" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Ailaysa Speech" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa's AI Speech can assist you in transforming transcribe (audio to text) or speech generation (text to audio). it enables speech to speech translations." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/ailaysa-speech" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Ailaysa Speech"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa's AI Speech can assist you in transforming transcribe (audio to text) or speech generation (text to audio). it enables speech to speech translations." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'ailaysa-transeditor':{
            return(
                <Helmet>
                    <title>Ailaysa Transeditor</title>
                    <meta data-react-helmet="true" name="title" content="Ailaysa Transeditor" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa Transeditor helps you translate content quickly and accurately with machine translation, then customize it with human post-editing. Save 10x time & money." />
                    <meta data-react-helmet="true" name="keywords" content="AI translation, localization, machine translation, post editing, post-editing, CAT, translation memories, glossaries" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/ailaysa-transeditor" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Ailaysa Transeditor" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa Transeditor helps you translate content quickly and accurately with machine translation, then customize it with human post-editing. Save 10x time & money." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/ailaysa-transeditor" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Ailaysa Transeditor"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa Transeditor helps you translate content quickly and accurately with machine translation, then customize it with human post-editing. Save 10x time & money." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'ailaysa-projects':{
            return(
                <Helmet>
                    <title>Ailaysa Projects</title>
                    <meta data-react-helmet="true" name="title" content="Ailaysa Projects" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa Projects is a project management application for managing your AI writing, AI translation, app and website localizations, and other AI language projects." />
                    <meta data-react-helmet="true" name="keywords" content="Project Management, Translation Project Management, TMS, Localization Project Management, Language Management, Metalingual Content Management" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/ailaysa-projects" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Ailaysa Projects" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa Projects is a project management application for managing your AI writing, AI translation, app and website localizations, and other AI language projects." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/ailaysa-projects" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Ailaysa Projects"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa Projects is a project management application for managing your AI writing, AI translation, app and website localizations, and other AI language projects." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'ailaysa-writer':{
            return(
                <Helmet>
                    <title>Ailaysa Writer</title>
                    <meta data-react-helmet="true" name="title" content="Ailaysa Writer" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa Writer is a Multilingual AI powered automated content creation tool to generate professional, marketing-focused content. Get quality content in no time!" />
                    <meta data-react-helmet="true" name="keywords" content="Multilingual AI, AI writing, AI assisted Writing, copy writing, AI content writer,  multilingual content, social media content, content marketing, blogs, marketing copies, sales copies, product descriptions" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/ailaysa-writer" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Ailaysa Writer" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa Writer is a Multilingual AI powered automated content creation tool to generate professional, marketing-focused content. Get quality content in no time!" />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/ailaysa-writer" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Ailaysa Writer"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa Writer is a Multilingual AI powered automated content creation tool to generate professional, marketing-focused content. Get quality content in no time!" />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'pricing':{
            return(
                <Helmet>
                    <title>Pricing</title>
                    <meta data-react-helmet="true" name="title" content="Pricing" />
                    <meta data-react-helmet="true" name="description" content="Begin utilizing Ailaysa for free, and then purchase pay-as-you-go credits or budget friendly subscriptions with either monthly or annual benefits." />
                    <meta data-react-helmet="true" name="keywords" content="plans, subscriptions, free tier, free translation, free AI writing, Pay as you go, credits, enterprise subscriptions, customization" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/pricing" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Pricing" />
                    <meta data-react-helmet="true" property="og:description" content="Begin utilizing Ailaysa for free, and then purchase pay-as-you-go credits or budget friendly subscriptions with either monthly or annual benefits." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/pricing" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Pricing"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Begin utilizing Ailaysa for free, and then purchase pay-as-you-go credits or budget friendly subscriptions with either monthly or annual benefits." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'contact':{
            return(
                <Helmet>
                    <title>Contact us</title>
                    <meta data-react-helmet="true" name="title" content="Contact us" />
                    <meta data-react-helmet="true" name="description" content="Reach out to Ailaysa, to expand your business, need language and translation assistance, or require any technological help." />
                    <meta data-react-helmet="true" name="keywords" content="plans, subscriptions, free tier, free translation, free AI writing, Pay as you go, credits, enterprise subscriptions, customization" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/contact" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Contact us" />
                    <meta data-react-helmet="true" property="og:description" content="Reach out to Ailaysa, to expand your business, need language and translation assistance, or require any technological help." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/contact" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Contact us"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Reach out to Ailaysa, to expand your business, need language and translation assistance, or require any technological help." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'career':{
            return(
                <Helmet>
                    <title>Career</title>
                    <meta data-react-helmet="true" name="title" content="Career" />
                    <meta data-react-helmet="true" name="description" content="We keep updating job positions available with us. These days most of the jobs are work from home. This is a global business and you can join from around the world." />
                    <meta data-react-helmet="true" name="keywords" content="react developers, python developer, hiring, graphic designers, product designers" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/career" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Career" />
                    <meta data-react-helmet="true" property="og:description" content="We keep updating job positions available with us. These days most of the jobs are work from home. This is a global business and you can join from around the world." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/career" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Career"/>
                    <meta data-react-helmet="true" name="twitter:description" content="We keep updating job positions available with us. These days most of the jobs are work from home. This is a global business and you can join from around the world." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'become-a-vendor':{
            return(
                <Helmet>
                    <title>Become a vendor</title>
                    <meta data-react-helmet="true" name="title" content="Become a vendor" />
                    <meta data-react-helmet="true" name="description" content="Sign up with Ailaysa Marketplace and begin making money! We are an open platform for freelance language experts, translators, editors, and agencies." />
                    <meta data-react-helmet="true" name="keywords" content="Freelancers, freelance translators, freelancer editors, translation agencies, content developers, writers, copy editors, LSPs" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/become-a-vendor" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Become a vendor" />
                    <meta data-react-helmet="true" property="og:description" content="Sign up with Ailaysa Marketplace and begin making money! We are an open platform for freelance language experts, translators, editors, and agencies." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/become-a-vendor" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Become a vendor"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Sign up with Ailaysa Marketplace and begin making money! We are an open platform for freelance language experts, translators, editors, and agencies." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'about':{
            return(
                <Helmet>
                    <title>About us</title>
                    <meta data-react-helmet="true" name="title" content="About us" />
                    <meta data-react-helmet="true" name="description" content="Ailaysa is a multilingual AI Saas platform to generate content in text, audio and image formats across one or more languages. Get professional content quickly and easily!" />
                    <meta data-react-helmet="true" name="keywords" content="Saas, Multilingual AI, Langscape,  Chennai,  Language, Translation, Localization and AI Writing. Machine Translation" />
                    <link data-react-helmet="true" rel="canonical" href="https://ailaysa.com/about" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="About us" />
                    <meta data-react-helmet="true" property="og:description" content="Ailaysa is a multilingual AI Saas platform to generate content in text, audio and image formats across one or more languages. Get professional content quickly and easily!" />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://ailaysa.com/about" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="About us"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Ailaysa is a multilingual AI Saas platform to generate content in text, audio and image formats across one or more languages. Get professional content quickly and easily!" />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
        case 'privacy-policy':{
            return(
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta name="description" content="Privacy Policy" />
                    <link rel="canonical" href="https://ailaysa.com/privacy-policy"/>
                </Helmet>
            )
        }
        case 'terms-of-service':{
            return(
                <Helmet>
                    <title>Terms and Conditions</title>
                    <meta name="description" content="Terms and Conditions" />
                    <link rel="canonical" href="https://ailaysa.com/terms-of-service"/>
                </Helmet>
            )
        }
        case 'world-book-day-offer':{
            return(
                <Helmet>
                    <title>Celebrate world book day with Ailaysa | Multilingual AI</title>
                    <meta name="description" content="Start translating your book at ease with Ailaysa's AI translation technology and editing features." />
                    <link rel="canonical" href="https://ailaysa.com/world-book-day-offer"/>
                </Helmet>
            )
        }
        case 'bibf-offer':{
            return(
                <Helmet>
                    <title>Celebrate beijing international book fair 2023 with Ailaysa | Multilingual AI</title>
                    <meta name="description" content="Start translating your book at ease with Ailaysa's AI translation technology and editing features." />
                    <link rel="canonical" href="https://ailaysa.com/bibf-offer"/>
                </Helmet>
            )
        }
        case 'newdelhi2024':{
            return(
                <Helmet>
                    <title>Ailaysa NDWBF 2024 Offer | Multilingual AI</title>
                    <meta name="description" content="Start translating your book at ease with Ailaysa's AI translation technology and editing features." />
                    <link rel="canonical" href="https://ailaysa.com/newdelhi2024"/>
                </Helmet>
            )
        }
        case 'kanitamil2024':{
            return(
                <Helmet>
                    <title>Kanitamil 2024 | Multilingual AI</title>
                    <meta name="description" content="Start translating your book at ease with Ailaysa's AI translation technology and editing features." />
                    <link rel="canonical" href="https://ailaysa.com/kanitamil2024"/>
                </Helmet>
            )
        }
        default: {
            return(
                <Helmet>
                    <title>Multilingual AI Writing and Translation | Instant</title>
                    <meta data-react-helmet="true" name="title" content="Multilingual AI Writing and Translation | Instant" />
                    <meta data-react-helmet="true" name="description" content="Write, translate, generate voice and images, all in multiple languages simultaneously with Ailaysa AI." />
                    <meta data-react-helmet="true" name="keywords" content="AI, AI writing, AI translation, AI images, AI voice, Machine Translation, Post-editing" />
                    <link data-react-helmet="true" rel="canonical" href="https://www.ailaysa.com" />
        
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta data-react-helmet="true" property="og:site_name" content="Ailaysa" />
                    <meta data-react-helmet="true" property="og:title" content="Multilingual AI Writing and Translation | Instant" />
                    <meta data-react-helmet="true" property="og:description" content="Write, translate, generate voice and images, all in multiple languages simultaneously with Ailaysa AI." />
                    <meta data-react-helmet="true" property="og:image" itemprop="image" content="https://ailaysa.com/logo512.png" />
                    <meta data-react-helmet="true" property="og:image:url"  content="https://ailaysa.com/logo512.png"  />
                    <meta data-react-helmet="true" property="og:url" content="https://www.ailaysa.com" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
        
        
                    {/* <!-- Twitter Meta Tags --> */}
                    <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                    <meta data-react-helmet="true" name="twitter:title" content="Multilingual AI Writing and Translation | Instant"/>
                    <meta data-react-helmet="true" name="twitter:description" content="Write, translate, generate voice and images, all in multiple languages simultaneously with Ailaysa AI." />
                    <meta data-react-helmet="true" name="twitter:image" content="https://ailaysa.com/logo512.png" />
                </Helmet>
            )
        }
    }
    
}

export default HeaderTags;
import React, { useEffect, useState, useRef} from "react";
import ForgotPwd from './ForgotAndResetPwdComponents/ForgotPwd'
import ResetPwdMailSentConfirmation from "./ForgotAndResetPwdComponents/ResetPwdMailSentConfirmation";

const ForgotPassword = (props) => {
    const { 
        switchView,
        setSwitchView,
        handleChangeSwitch,
        isSeperateAuth
    } = props

    const [forgotPwdSteps, setForgotPwdSteps] = useState("step-1")

    const handleForgotPwdStepChange = (switchTxt) => {
        setForgotPwdSteps(switchTxt)
    }
    return (
        <>
            {
                forgotPwdSteps === "step-1" ?
                <ForgotPwd switchView={switchView} setSwitchView={setSwitchView} handleChangeSwitch={handleChangeSwitch} handleForgotPwdStepChange={handleForgotPwdStepChange} isSeperateAuth={isSeperateAuth}/>
                :
                forgotPwdSteps === "step-2" &&
                <ResetPwdMailSentConfirmation handleChangeSwitch={handleChangeSwitch} isSeperateAuth={isSeperateAuth}/>
            }
        </>
    )
}

export default ForgotPassword
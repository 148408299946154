import React from 'react'
import { ButtonBase } from '@mui/material'
import MailIcon from '../../styles/svg-icons/MailIcon'
import { Link , useNavigate} from 'react-router-dom'
import AilaysaBlackIcon from '../../styles/svg-icons/AilaysaBlackIcon'


const ResetPwdMailSentConfirmation = (props) => {
    const { 
        switchView,
        setSwitchView,
        handleChangeSwitch,
        isSeperateAuth
    } = props

    const navigate = useNavigate()

    return (
        <div className="signup-main-wrapper reset-pwd-mail-confirm">
            <div className="signup-inner-wrapper">
                <div className="ai-mob-logo" onClick={() => navigate("/")}>
                    <AilaysaBlackIcon />
                </div>
                <h3 className="title">Reset password email sent</h3>
                <div className="confirm-icon-wrapper">
                    <MailIcon />
                </div>
                <div className="mail-sent-info">
                    <p className="note">We've sent a password recovery link to</p>
                    <p className="email">Sathyaprakash@gmail.com</p>
                </div>
                <ButtonBase className="global-submit-btn" onClick={() => {isSeperateAuth ? navigate("/auth/login") : handleChangeSwitch("login")}}>
                    Back to login
                </ButtonBase>
            </div>
        </div>
    )
}

export default ResetPwdMailSentConfirmation
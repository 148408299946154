import React, { useEffect, useState, useRef} from "react";
import { ButtonBase } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ForgotLockIcon from "../../styles/svg-icons/ForgotLockIcon";
import { Link , useNavigate} from 'react-router-dom'
import AilaysaBlackIcon from "../../styles/svg-icons/AilaysaBlackIcon";

const ForgotPwd = (props) => {

    const { 
        switchView,
        setSwitchView,
        handleChangeSwitch,
        handleForgotPwdStepChange,
        isSeperateAuth
    } = props

    const navigate = useNavigate()


  return (
    <div className="signup-main-wrapper forgot-pwd">
        <div className="signup-inner-wrapper">
            <div className="ai-mob-logo" onClick={() => navigate("/")}>
                <AilaysaBlackIcon />
            </div>
            <div className="forgot-pwd-icon">
                <ForgotLockIcon />
            </div>
            <div className="header-row">
                <span className="back-btn-wrap" onClick={() => {isSeperateAuth ? navigate("/auth/login") : handleChangeSwitch("login")}}><ArrowBackIosNewIcon className="arrow-back"/></span>
                <h3 className="title">Forgot your password?</h3>
                <p className="sub-title">We'll help you reset it.</p>
            </div>
            <div className="form-main-wrapper">
                <div className="form-group">
                    <label>Email</label>
                    <input id="email" type="email" name="email" className="form-text-box"/>
                </div>
            </div>
            <ButtonBase className="global-submit-btn" onClick={() => handleForgotPwdStepChange("step-2")}>
                Reset Password
            </ButtonBase>
        </div>
    </div>
  )
}

export default ForgotPwd